<template>
	<section class="configuracion w-100">
		<navbar-admin icon="icon-dolar"/>

		<div class="row mt-2 ml-4 mb-4">
			<div class="col-10 px-2">
				<div class="d-flex flex-wrap justify-content-between align-items-center bg-f9 border br-5 py-2 px-3">
					<p class="f-600">{{ cabeceraDetalle.nombre }}</p>
					<p class="f-13 f-500">Porcentaje de utilidad: {{ cabeceraDetalle.porcentaje_utilidad }}%</p>
					<el-tooltip content="Cantidad y valor de los materiales" effect="light" :visible-arrow="false">
						<div class="d-flex align-items-center">
							<i class="icon-format-list-numbered f-22"/>
							<span class="f-13 ml-2 f-500">{{ cabeceraDetalle.numero_materiales }} - {{ cabeceraDetalle.valor_total_materiales | currency('US $ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</span>
						</div>
					</el-tooltip>
					<p class="d-flex align-items-center">
						<i class="icon-account-clock f-22"/>
						<span class="f-13 ml-2 f-500">{{ cabeceraDetalle.horas_hombre }} Hrs. - {{ cabeceraDetalle.valor_total_horas_hombre | currency('US $ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</span>
					</p>
				</div>
			</div>
			<div class="col-2 px-2">
				<el-tooltip effect="light" :visible-arrow="false" popper-class="tooltip-size">
					<template slot="content">
						<p class="text-center">Valor total de la configuración de la etapa</p>
					</template>
					<div class="d-flex justify-content-between align-items-center bg-f9 border br-5 py-2 px-3">
						<p class="f-600 py-1 text-center">{{ cabeceraDetalle.valor_total | currency('US $ ', 2, { thousandsSeparator: '.', decimalSeparator: ',' }) }}</p>
					</div>
				</el-tooltip>
			</div>
		</div>
		<div class="ml-5">
			<titulo-divisor titulo="Listado de materiales">
				<div class="d-flex ">
					<input-search 
					v-model="buscarMateriales"
					mode="server"
					:auto-search="true"
					:search="search"
					placeholder="Buscar material en la lista"
					/>
					<filter-indicator 
					class="mx-2" 
					:count="filterCount" 
					@clearFilter="clearFilter" 
					@openModalFilter="openModalFilterMateriales"
					/>
				</div>
			</titulo-divisor>
		</div>
		<div class="ml-5">
        <tabla-general :mostrarBuscador="false" alturaTabla="550px" :usarServidor="true" :usarPaginacion="true" :servidorData="materiales" @paginar="listarMateriales">
				<el-table-column label="Referencia" width="120">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.ref }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Nombre" min-width="250">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.nombre }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Tipo" width="150">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.tipo }}</p>
					</template>
				</el-table-column>
				<el-table-column label="U.M." width="150" align="center">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.unidad_medida }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Unidades" width="200" align="center" header-align="center">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.unidades }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Valor" width="250" align="center" header-align="center">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.valor | currency('$', 2, { thousandsSeparator: '.' }) }}</p>
					</template>
				</el-table-column>
				<el-table-column label="Plan de mantenimiento" min-width="150">
					<template slot-scope="scope">
						<p class="f-12 text-muted2 tres-puntos">{{ scope.row.plan_mantenimiento }}</p>
					</template>
				</el-table-column>
			</tabla-general>
		</div>
		<modal-filter-materiales ref="modalFilterMateriales" :accionListar="listarMateriales" :clearFilter="clearFilter" />
	</section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
	components: {
		modalFilterMateriales: () => import('./partials/modalFilterMateriales.vue')
	},
	data(){
		return {
			tabla: this.$route.params.tabla,
			id_configuracion:this.$route.params.id_configuracion,
			query: '',
			buscarMateriales: ''
		}
	},
	computed: {
		filterCount(){
			return this.filterMateriales.count()
		},
		...mapGetters({
            materiales: 'ventas/presencia_equipos/materiales',
			filterMateriales: 'ventas/presencia_equipos/filterMateriales',
			cabeceraDetalle: 'ventas/presencia_equipos/cabeceraDetalle',
			detalleEquipo: 'ventas/presencia_equipos/detalleEquipo',
        }),
	},
	async created(){
		// if (!this.tabla) {
		// 	this.$router.push({ name: 'ventas.presencia-equipos.detalle', params: { id: this.$route.params.id_configuracion }})
		// 	return
		// }
		await this.listarCabecera(),
		await this.Action_get_filtro_materiales({ id:this.id_configuracion, tabla:this.tabla })
		await this.clearFilter()
		this.getBreadcumbs(['ventas.main']);
		this.spliceBreadcumbs([
		{
			position: 2,
			breadcumb: {
				name: this.detalleEquipo.nombre,
				route: 'ventas.presencia-equipos.detalle',
				params: {
					id: this.$route.params.id
				}
			}
		},
		{
			position: 3,
			breadcumb: {
				name: this.cabeceraDetalle.nombre,
				route: 'ventas.presencia-equipos.detalle.configuracion',
				params: {
					id: this.$route.params.id,
					id_configuracion: this.$route.params.id_configuracion,
					tabla: this.$route.params.tabla,
				}
			}
		}
		])
	},
	methods: {
		...mapActions({
			getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
			spliceBreadcumbs: 'navigation/breadcumbs/spliceBreadcumbs',
			Action_get_materiales: 'ventas/presencia_equipos/Action_get_materiales',
			Action_get_cabecera_detalle_materiales: 'ventas/presencia_equipos/Action_get_cabecera_detalle_materiales',
			Action_clean_filter_materiales: 'ventas/presencia_equipos/Action_clean_filter_materiales',
			Action_get_filtro_materiales: 'ventas/presencia_equipos/Action_get_filtro_materiales',

		}),
		async listarCabecera(){
			const payload = {
				id:this.id_configuracion,
				tabla:this.tabla
			}
			await this.Action_get_cabecera_detalle_materiales(payload)
		},
		async listarMateriales(page=1){
			const payload = {
				id:this.id_configuracion,
				source:this.tabla,
				page:page,
				buscar:this.buscarMateriales
			}
			await this.Action_get_materiales(payload)
		},
		paginate(page){
			console.log(page);
		},
		openModalFilterMateriales(){
			this.$refs.modalFilterMateriales.toggle();
		},
		async clearFilter(){
			this.buscarMateriales = ''
			this.Action_clean_filter_materiales()
			await this.listarMateriales()
		},
		async search(){
			await this.listarMateriales()
		}
	}
}
</script>

<style lang="scss">
.tooltip-size{
	width: 200px !important;
}
</style>